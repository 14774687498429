<template>
  <EditorContent :editor="editor" />
</template>

<script lang="ts">
import { Editor, EditorContent, type Attribute } from '@tiptap/vue-3'
import { StarterKit } from '@tiptap/starter-kit'
import type { ShallowRef } from 'vue'
import { Document } from '@tiptap/extension-document'
import { Table } from '@tiptap/extension-table'
import { TableCell } from '@tiptap/extension-table-cell'
import { TableHeader } from '@tiptap/extension-table-header'
import { TableRow } from '@tiptap/extension-table-row'
import { TextAlign } from '@tiptap/extension-text-align'
import { Image } from '@tiptap/extension-image'
import Focus from '@tiptap/extension-focus'
import { Plugin } from 'prosemirror-state'
import type { Transaction } from '@tiptap/pm/state'
import type { ReplaceAroundStep, ReplaceStep } from '@tiptap/pm/transform'
import {
  IMAGE_ATTRIBUTES,
  TABLE_ATTRIBUTES,
  TABLE_CELL_ATTRIBUTES,
  TABLE_ROW_ATTRIBUTES
} from '~/constants/bdese'

export default {
  name: 'BdeseWysiwygBase',
  components: {
    EditorContent
  },
  props: {
    initialContent: {
      type: String,
      required: true
    },
    isInEditionMode: {
      type: Boolean,
      default: false
    }
  },
  emits: ['edited-content'],
  data(): {
    editor: ShallowRef<Editor> | undefined
  } {
    return {
      editor: undefined
    }
  },
  watch: {
    initialContent(newValue) {
      this.editor?.commands.setContent(newValue)
    }
  },
  mounted() {
    const CustomTable = Table.extend({
      addAttributes() {
        return Object.values(TABLE_ATTRIBUTES).reduce<{
          [key: string]: Partial<Attribute>
        }>(
          (config, customAttributeKey) => ({
            ...config,
            [customAttributeKey]: {
              default: null
            }
          }),
          {}
        )
      }
    })

    const CustomTableRow = TableRow.extend({
      addAttributes() {
        return {
          [TABLE_ROW_ATTRIBUTES.IS_HEADER_ROW]: {
            default: null,
            parseHTML: element =>
              element.getAttribute(TABLE_ROW_ATTRIBUTES.IS_HEADER_ROW),
            renderHTML: attributes => {
              if (attributes[TABLE_ROW_ATTRIBUTES.IS_HEADER_ROW]) {
                return {
                  ...attributes,
                  class: attributes.class
                    ? `${attributes.class} editor-table-header-row`
                    : 'editor-table-header-row'
                }
              }
            }
          },
          [TABLE_ROW_ATTRIBUTES.IS_TOTAL_ROW]: {
            default: null,
            parseHTML: element =>
              element.getAttribute(TABLE_ROW_ATTRIBUTES.IS_TOTAL_ROW),
            renderHTML: attributes => {
              if (attributes[TABLE_ROW_ATTRIBUTES.IS_TOTAL_ROW]) {
                return {
                  ...attributes,
                  class: attributes.class
                    ? `${attributes.class} editor-table-total-row`
                    : 'editor-table-total-row'
                }
              }
            }
          }
        }
      }
    })

    // The sole purpose of this plugin is to modify the 'edited' attribute of the table cells that have been changed.
    const editedTableCellPlugin = new Plugin({
      appendTransaction(transactions, _, newEditorState) {
        let tr: Transaction | null = null

        if (transactions[0] === undefined) return

        const replaceSteps = transactions
          .map(transaction => transaction.steps)
          .flat()
          .filter(
            step =>
              (step as ReplaceStep | ReplaceAroundStep).from !== undefined &&
              (step as ReplaceStep | ReplaceAroundStep).to !== undefined
          ) as (ReplaceStep | ReplaceAroundStep)[]

        if (replaceSteps.length === 0) return

        // Get max pos and min pos of edited nodes from steps
        const minPos = Math.min(
          ...replaceSteps.map(replaceStep => replaceStep.from)
        )

        const maxPos = Math.max(
          ...replaceSteps.map(replaceStep => replaceStep.to)
        )

        // We need to execute this logic in a try/catch because 'nodesBetween' can return errors for some positions
        try {
          transactions[0].doc.nodesBetween(minPos, maxPos, (node, pos) => {
            if (
              node.type.name === 'tableCell' &&
              node.attrs[TABLE_CELL_ATTRIBUTES.INITIAL_VALUE]
            ) {
              const isEdited =
                node.textContent !==
                node.attrs[TABLE_CELL_ATTRIBUTES.INITIAL_VALUE]

              if (!tr) {
                tr = newEditorState.tr
              }

              tr = tr.setNodeMarkup(pos, null, {
                ...node.attrs,
                [TABLE_CELL_ATTRIBUTES.EDITED]: isEdited.toString()
              })
            }
          })
        } catch {}

        if (!tr) return

        return tr
      }
    })

    const CustomTableHeader = this.isInEditionMode
      ? TableHeader.extend({
          addAttributes() {
            return {
              ...this.parent?.(),
              [TABLE_CELL_ATTRIBUTES.INITIAL_VALUE]: {
                default: null,
                parseHTML: element =>
                  element.getAttribute(TABLE_CELL_ATTRIBUTES.INITIAL_VALUE),
                renderHTML: attributes => ({
                  ...attributes
                })
              },
              [TABLE_CELL_ATTRIBUTES.DIMENSIONS_WITH_VALUE]: {
                default: null,
                parseHTML: element =>
                  element.getAttribute(
                    TABLE_CELL_ATTRIBUTES.DIMENSIONS_WITH_VALUE
                  ),
                renderHTML: attributes => ({
                  ...attributes
                })
              }
            }
          }
        })
      : TableHeader

    const CustomTableCell = this.isInEditionMode
      ? TableCell.extend({
          addProseMirrorPlugins() {
            return [editedTableCellPlugin]
          },
          addAttributes() {
            return {
              ...this.parent?.(),
              [TABLE_CELL_ATTRIBUTES.INITIAL_VALUE]: {
                default: null,
                parseHTML: element =>
                  element.getAttribute(TABLE_CELL_ATTRIBUTES.INITIAL_VALUE),
                renderHTML: attributes => ({
                  ...attributes
                })
              },
              [TABLE_CELL_ATTRIBUTES.DIMENSIONS_WITH_VALUE]: {
                default: null,
                parseHTML: element =>
                  element.getAttribute(
                    TABLE_CELL_ATTRIBUTES.DIMENSIONS_WITH_VALUE
                  ),
                renderHTML: attributes => ({
                  ...attributes
                })
              },
              [TABLE_CELL_ATTRIBUTES.EDITED]: {
                default: false,
                parseHTML: element =>
                  element.getAttribute(TABLE_CELL_ATTRIBUTES.INITIAL_VALUE) &&
                  element.getAttribute(TABLE_CELL_ATTRIBUTES.INITIAL_VALUE) !==
                    element.textContent,
                renderHTML: attributes => {
                  if (attributes[TABLE_CELL_ATTRIBUTES.INITIAL_VALUE]) {
                    if (
                      attributes[TABLE_CELL_ATTRIBUTES.EDITED].toString() ===
                      'true'
                    ) {
                      return {
                        ...attributes,
                        class: attributes.class
                          ? `${attributes.class} edited`
                          : 'edited'
                      }
                    } else {
                      return {
                        ...attributes,
                        class: attributes.class
                          ? `${attributes.class} initial-value`
                          : 'initial-value'
                      }
                    }
                  }
                }
              }
            }
          }
        })
      : TableCell

    const CustomImage = Image.extend({
      addAttributes() {
        return {
          height: {
            default: 500
          },
          src: {
            default: null
          },
          ...Object.values(IMAGE_ATTRIBUTES).reduce<{
            [key: string]: Partial<Attribute>
          }>(
            (config, customAttributeKey) => ({
              ...config,
              [customAttributeKey]: {
                default: null
              }
            }),
            {}
          )
        }
      }
    })

    this.editor = new Editor({
      editorProps: {
        attributes: {
          class: 'editor'
        }
      },
      editable: this.isInEditionMode,
      content: this.initialContent,
      onUpdate: () => {
        this.$emit('edited-content')
      },
      extensions: [
        Document,
        CustomTable.configure({
          resizable: false
        }),
        CustomTableRow,
        CustomTableHeader,
        CustomTableCell,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
          defaultAlignment: ''
        }),
        Focus.configure({
          className: 'editor-focused-node'
        }),
        CustomImage.configure({
          allowBase64: true
        }),
        StarterKit.configure({
          document: false,
          heading: {
            levels: [2, 3, 4]
          }
        })
      ]
    })
  },
  beforeUnmount() {
    this.editor?.destroy()
  }
}
</script>

<style lang="scss">
/* stylelint-disable no-descending-specificity */
.editor {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: $margin-regular $margin-medium;

  h2 {
    @include font-text($font-weight-medium);
    @include font-size(21px);
    margin-bottom: $margin-regular;
    margin-top: $margin-small;
    color: $text-secondary;
  }

  h3 {
    @include font-text($font-weight-book);
    @include font-size(16px);
    margin-bottom: $margin-regular;
    margin-top: $margin-small;
    color: $text-secondary;
  }

  h4 {
    @include font-text($font-weight-book);
    @include font-size(15px);
    margin-bottom: $margin-regular;
    margin-top: $margin-small;
    color: $text-secondary;
  }

  p {
    @include font-size(13px);
    @include font-text($font-weight-book);
    color: $text-primary;
    margin-bottom: $margin-mini;
  }

  strong {
    @include font-text($font-weight-bold);
  }

  em {
    font-style: italic;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  ul > li {
    list-style-type: disc;
  }

  ol > li {
    list-style-type: decimal;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    table-layout: auto;
    border-radius: 8px;
    width: 100%;
    margin: 0;
    box-shadow: 0 0 0 1px $border-ternary;
    overflow: hidden;
    margin-bottom: $margin-medium;

    td,
    th {
      padding: 4px $margin-regular;
      position: relative;
      box-sizing: border-box;
      vertical-align: middle;
      height: 40px;

      &:not(:last-child) {
        border-right: 1px solid $border-ternary;
      }

      &.editor-focused-node {
        z-index: 2;
        border-radius: 4px;
        border: 1px solid $button-primary !important;
      }

      > * {
        margin-bottom: 0;
      }
    }

    td.initial-value {
      background-color: $bg-initial-value;
    }

    td.edited {
      background-color: $bg-edited;
    }

    tr {
      &:nth-child(even) {
        background-color: $bg-secondary;
      }

      &:nth-child(odd) {
        background-color: $bg-quaternary;
      }

      &.editor-table-header-row {
        > th {
          border-bottom: 1px solid $border-ternary;
          text-align: center;
        }
      }

      &.editor-table-total-row {
        > td,
        th {
          border-top: 1px solid $border-ternary;
        }

        > td {
          background-color: $bg-quinary;
        }
      }
    }

    th {
      background-color: $bg-quinary;
      text-align: left;
    }

    td {
      text-align: center;
    }
  }

  img {
    object-fit: contain;

    &.editor-focused-node {
      border: 1px solid $button-primary !important;
    }
  }
}

// warning: The following classes column-resize-handle, selectedCell, tableWrapper, resize-cursor, ProseMirror-focused are created by tiptap, do not change their names
.selectedCell::after {
  z-index: 2;
  position: absolute;
  content: '';
  inset: 0;
  background-color: $color-blue-button-hover;
  pointer-events: none;
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: $margin-small;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.ProseMirror-focused {
  outline: none;
}

.ProseMirror-gapcursor {
  position: inherit !important;

  ::after {
    position: inherit !important;
  }
}
// end of warning
/* stylelint-enable no-descending-specificity */
</style>
